import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ColumnCard from '../components/column-card'
import Footer from '../components/footer-component'
import ContactUs from '../components/contact-us'
import ContactForm from '../components/contact-form'
import Img from 'gatsby-image'
import ImageSlider from '../components/image-carousel-slider'
import AdvantagesComponent from '../components/advantages-component'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import ReactGA from 'react-ga';
import '../components/form-background.css'

var metaDescription = "Sparkle advocates that best tasting foods are found at home. New and exciting promos await millions of customers in this future super app.";
var metaImage = "https://www.sparkles.com.ph/headline_header_banner.png";
var keywords = ["homemade", "ecommerce", "philippines", "sparkles", "star", "sparklestar", "local"];
var title = "Sparkle";

class RootIndex extends React.Component {

  render() {
    ReactGA.initialize('UA-173956947-1');
    ReactGA.pageview('/');
    const meta = get(this, 'props.data.site.siteMetadata')
    const images = get(this, 'props.data.contentfulSlider')
    const advantageRow = get(this, 'props.data.contentfulAdvantageRow')
    const [featureRow] = get(this, 'props.data.allContentfulFeaturesRow.edges')
    const heroData = get(this, 'props.data.allContentfulHero.nodes')
    const noodles = get(this, 'props.data.file.childImageSharp.fixed')
    return (

      <Layout location={this.props.location}>
        <Helmet
          title="Sparkle" >
          <meta name="description" content={meta.defaultDescription} />
          <meta name="image" content={meta.defaultImage} />
          <meta property="og:url" content={meta.siteUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.defaultTitle} />
          <meta property="og:description" content={meta.defaultDescription} />
          <meta property="og:image" content={meta.siteUrl + '/headline_header_bannerfb.png'} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={meta.twitterUsername} />
          <meta name="twitter:title" content={meta.defaultTitle} />
          <meta name="twitter:description" content={meta.defaultDescription} />
          <meta name="twitter:image" content={meta.siteUrl + meta.defaultImage} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon"></link>
          <link rel="icon" href="/favicon.ico" type="image/x-icon"></link>
        </Helmet>
        <div style={{ background: '#fff' }} id="home">
          <Hero heroData={heroData} />
          <ImageSlider images={images.images} />
          <div id="features" style={{ maxWidth: 1180, margin: '0 auto' }}>
            <div className="wrapper">
              <h2 className="feature-headline">{featureRow.node.title}</h2>
              <p className="feature-subtitle">Sparkle advocates that best tasting foods are found at home. New and exciting promos await millions of customers in this future super app.</p>
              <div className="feature-row">
                {featureRow.node.feature.map((feature) => {
                  return (
                    <div key={feature.id}>
                      <ColumnCard data={feature} />
                    </div>)
                })}
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: '#f9faff' }}>
          <div style={{ maxWidth: 1180, margin: '0 auto' }}>
            <div className="wrapper">
              <AdvantagesComponent advantageRow={advantageRow} />
            </div>
          </div>
        </div>
        <div style={{ background: '#fff' }}>
          <div className="FormBG">
            <div id="contact">
              <div style={{ maxWidth: 1180, margin: '0 auto' }}>
                <div className="wrapper">
                  <h2 className="form-headline">STARt and Sparkle Now!</h2>
                  <div className="form-container">
                    <div className="column-1">
                      <ContactForm />
                    </div>
                    <div className="column-2">
                      <ContactUs />
                      <Img className="noodles" fixed={noodles} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MessengerCustomerChat
                pageId="100387048389171"
                appId="297919214659269"
                greetingDialogDisplay='show'
                greetingDialogDelay={40}
              />
            </div>
            <footer>
              <Footer />
            </footer>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    contentfulSlider {
      images{
        fluid{
          ...GatsbyContentfulFluid_tracedSVG
        }
        description
      }
    }
    allContentfulFeaturesRow {
      edges {
        node {
          title
          feature {
            id
            description
            title
            logo {
              description
              fluid {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "Spark--noodles.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    contentfulAdvantageRow {
      header
      description {
        description
      }
      advantages {
        advantageImage {
          description
          fluid {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        firstdescription {
          firstdescription
        }
        seconddescription {
          seconddescription
        }
      }
    }
    allContentfulHero {
      nodes {
        imageTitle {
          fluid {
            ...GatsbyContentfulFluid_tracedSVG
          }
          title
        }
        description {
          description
        }
        mobileImage {
          fluid {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
