import React from 'react';
import Img from "gatsby-image"
import Carousel from 'nuka-carousel';
import './image-carousel.css'


export default ({ images }) => {
    const imgTagList = images.map(image => {
        return <Img className='img' fluid={image.fluid} alt={image.fluid.description} />
    })
    return (
        <center>
            <Carousel autoplay={true} autoplayInterval={5000} wrapAround={true} height={"500px"} width={"100%"} withoutControls={true}>
                {
                    imgTagList
                }
            </Carousel>
        </center>
    )
}
