import React from 'react'
import AdvantageCard from './advantage-card'
import './advantage-card-style.css'

export default ({ advantageRow }) => (
    <div>
        <center><h1 className="header">{advantageRow.header}</h1></center>
        <center><h2 className="description">{advantageRow.description.description}</h2></center>
        <center>
            <div className="advantagesDiv">
                <AdvantageCard advantageImage={advantageRow.advantages[0].advantageImage.fluid} firstDesc={advantageRow.advantages[0].firstdescription.firstdescription} secondDesc={advantageRow.advantages[0].seconddescription.seconddescription} imgPosition={"left"} />
                <AdvantageCard advantageImage={advantageRow.advantages[1].advantageImage.fluid} firstDesc={advantageRow.advantages[1].firstdescription.firstdescription} secondDesc={advantageRow.advantages[1].seconddescription.seconddescription} imgPosition={"right"} />
                <AdvantageCard advantageImage={advantageRow.advantages[2].advantageImage.fluid} firstDesc={advantageRow.advantages[2].firstdescription.firstdescription} secondDesc={advantageRow.advantages[2].seconddescription.seconddescription} imgPosition={"left"} />
            </div>
        </center>
    </div>
)
