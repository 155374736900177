import React from 'react'
import Img from "gatsby-image"
import './advantage-card-style.css'


export default (props) => {

    let className = props.imgPosition == "left" ? "ImageLeftContainer" : "ImageRightContainer"

    return (
        <div className='cardBorder'>
            <div className={className}>
                <Img className="Image" fluid={props.advantageImage} />
            </div>
            <div className="text">
                <ul className='paragraphListStyle'>
                    <li className="list-item">{props.firstDesc}</li>
                    <li className="list-item">{props.secondDesc}</li>
                </ul>
            </div>
        </div>
    )
}

// className='Image'
