import React from 'react'
import Img from 'gatsby-image'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import style from './hero.module.css'

export default ({ heroData }) => {
  return (
    <div className={style.Hero}>


      <center>
        <div className={style.TopNav}>
          <img className={style.NavLogo} src={require('../images/sparkle_logo_white.png')} alt="Sparkle logo white"></img>
          <ul className={style.NavMenu}>
            <li><AnchorLink to='#home'>HOME</AnchorLink></li>
            <li><AnchorLink to='#features'>FEATURES</AnchorLink></li>
            <li><AnchorLink to='#contact'>CONTACT</AnchorLink></li>
          </ul>
        </div>


        <div className={style.HeroContent}>
          <div className={style.MainHeader}>
            <Img className={style.imageTitle} fluid={heroData[0].imageTitle.fluid} />
            <p>{heroData[0].description.description}</p>
            <div className={style.StoreContainer}>
              <AnchorLink to="https://apps.apple.com/ph/app/sparkle-taste-of-home/id1532361168">
                <img className={style.StoreLogo} src="appstore.svg" />
              </AnchorLink>
              

              {/* <a href='https://play.google.com/store/apps/details?id=com.sparkle.sparkle&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' className={style.PlayStore} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a> */}
              <AnchorLink to="https://play.google.com/store/apps/details?id=com.sparkle.sparkle&fbclid=IwAR3Msg7hSVciDy6UX0-MIFKpK6sxHxTwQ8ZDMz0_ZX4JdpnbgSdPThom8aA">
                 <img className={style.PlayStore} alt='Get it on Google Play'  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </AnchorLink>
            </div>
          </div>
          <div className={style.Mascot}>
            <Img fluid={heroData[0].mobileImage.fluid} />
          </div>
        </div>
      </center>
    </div>
  )
}
