import React from 'react'
import Img from 'gatsby-image'

import styles from './column-card.module.css'

export default ({ data }) => (
  <div className={styles.column}>
    <div className={styles.columnIcon}>
      <Img
        className={styles.columnImage}
        alt={data.logo.description}
        fluid={data.logo.fluid}
      />
    </div>
    <div className={styles.columnDetails}>
      <h2 className={styles.columnHeadline}>{data.title}</h2>
      <p className={styles.columnTitle}>{data.description}</p>
    </div>
  </div>
)
