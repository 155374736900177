import React from 'react'
import './select-options.css'
import styles from './contact-form.module.css'
import SelectSearch from 'react-select-search';
import { FacebookShareButton } from 'react-share'
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validNumberRegex = RegExp(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/);

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

const options = [
  {
    type: 'group',
    name: 'Metro Manila',
    items: [
      { name: 'Caloocan', value: 'Caloocan' },
      { name: 'Las Piñas', value: 'Las Pinas' },
      { name: 'Makati', value: 'Makati' },
      { name: 'Malabon', value: 'Malabon' },
      { name: 'Mandaluyong', value: 'Mandaluyong' },
      { name: 'Manila', value: 'Manila' },
      { name: 'Marikina', value: 'Marikina' },
      { name: 'Muntinlupa', value: 'Muntinlupa' },
      { name: 'Navotas', value: 'Navotas' },
      { name: 'Parañaque', value: 'Parañaque' },
      { name: 'Pasay', value: 'Pasay' },
      { name: 'Pasig', value: 'Pasig' },
      { name: 'Pateros', value: 'Pateros' },
      { name: 'Quezon City', value: 'Quezon' },
      { name: 'San Juan', value: 'San' },
      { name: 'Taguig', value: 'Taguig' },
      { name: 'Valenzuela', value: 'Valenzuela' },
    ]
  }
];

function renderCity(props, option, snapshot, className) {
  return (
    <button {...props} className={className} type="button">
      <span>{option.name}</span>
    </button>
  );
}

class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      number: null,
      location: null,
      errors: {
        name: '',
        email: '',
        number: '',
      },
      messageSent: true,
      loading: false
    };
  }


  submitClickedAgain = (e) => {
    e.preventDefault();
  }

  submitClicked = (e) => {
    e.preventDefault();
    var form = e.target;
    var data = {
      email: this.state.email,
      formDataNameOrder: '["name","email","number","location"]',
      formGoogleSendEmail: "",
      formGoogleSheetName: "responses",
      number: this.state.number,
      name: this.state.name,
      location: this.state.location
    };
    if (this.state.name != null && this.state.email != null && this.state.number != null && this.state.location != null && this.state.errors.name === '' && this.state.errors.number === '' && this.state.errors.email === '') {

      this.setState({ loading: true });
      var url = "https://script.google.com/macros/s/AKfycbyJdkV72RBGE9X2JeKwDNxwlDJyEC7H9Yr4B5M_JA/exec";
      
      if(this.state.role==="rider") {
        url = "https://script.google.com/macros/s/AKfycbzI4b9wRni89jNbgsSedmL4t4pv7yDDu1NuoTo6s7Bu919naWw/exec"
      }

      if(this.state.role==="buyer") {
        url = "https://script.google.com/macros/s/AKfycbwMGSqO-481XjAr_NQX9JvjorjAuV1h4B2OKqBo7Q/exec"
      }

      var xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      // xhr.withCredentials = true;
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.addEventListener('load', () => {

        this.setState({ messageSent: false, loading: false });
      })
      // xhr.onreadystatechange = function () {


      //   this.setState({messageSent: false});
      //   return;
      // };
      // url encode form data for sending as post data
      var encoded = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
      }).join('&');
      xhr.send(encoded);

    } else {
      // this.setState({errors, name: 'Fill up the fields.' });
      alert('Fill up fields.');
    }

  }

  handleSelect = (event) => {
    this.setState({ location: event + ", Metro Manila" })
  }

  handleRoleSelect = (event) => {
    this.setState({role: event.target.value});
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name':
        errors.name =
          value.length < 5
            ? 'Full Name must be 5 characters long!'
            : '';

        break;
      case 'email':
        errors.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email Address is not valid!';

        break;
      case 'number':
        errors.number =
          validNumberRegex.test(value)
            ? ''
            : 'Number is not valid! - 0912###';
      case 'location':
        errors.location =
          value.length < 8
            ? 'Location must be selected!'
            : '';
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });

  }

  render() {
    const { errors } = this.state;
    return (
      <div className={styles.contactForm}>
        <h3 className={styles.contactFormHeadline}>Want to be a pioneer?</h3>
        <div className={styles.contactFormColumn}>
          {
            this.state.role ? (
              <div className={styles.contactFormRow}>
              {
                this.state.messageSent ? (
                  <form className={styles.gforms} 
                  action="https://script.google.com/macros/s/AKfycbw00gEDMVGJG36MrRYR2aFrmHnn83u7Eifm3An4_L6LGymPwSCl/exec"
                  method="post" encType="text/plain" autoComplete="off">
                    <input type="text" id="name" name="name" placeholder="Name" onChange={this.handleChange} noValidate />
                    {errors.name.length > 0 && <span className={styles.error}>{errors.name}</span>}
                    <input type="text" id="email" name="email" placeholder="Email address" onChange={this.handleChange} noValidate />
                    {errors.email.length > 0 && <span className={styles.error}>{errors.email}</span>}
                    <input type="text" id="number" name="number" placeholder="Mobile Number" onChange={this.handleChange} noValidate />
                    {errors.number.length > 0 && <span className={styles.error}>{errors.number}</span>}
                    <SelectSearch options={options} className="select-search" renderOption={renderCity} value="" name="location" placeholder="Municipality/Town" search onChange={this.handleSelect} />
                    {this.state.loading ? (
                      <input type="submit" value="Submitting . . ." onClick={this.submitClickedAgain} />
                    ) : (
                        <input type="submit" value="Yes, I'm in" onClick={this.submitClicked} />
                      )}
  
                  </form>
                ) : (
                    <div className={styles.thankyou_message}>
                      <h2>THANKS FOR SIGNING UP!</h2>
                      {this.state.role==='merchant'?(
                        <p>We'll let you know when you can start cooking for your neighborhood.</p>)
                      :(
                        <></>
                      )}
                      {this.state.role==='rider'?(
                        <p>We'll let you know when you can start riding for your neighborhood.</p>)
                      :(
                        <></>
                      )}
                      {this.state.role==='buyer'?(
                        <p>We'll let you know when you can start chomping and sparkle.</p>)
                      :(
                        <></>
                      )}
                      <span className="shareButton">
                        <FacebookShareButton url="https://www.sparkles.com.ph/"><FaFacebook></FaFacebook> Share on Facebook</FacebookShareButton><br />
                      </span>
                      <span className="shareButton">
                        <a className="shareButtonLink" href="http://instagram.com/sparkles.com.ph"> <FaInstagram></FaInstagram> Visit our Instagram </a>
                      </span>
                    </div>
                  )
              }
            </div>
            ) : ( <div className="roles-container"> 
              <button onClick={this.handleRoleSelect} value="merchant" className="roles">Star Merchant</button>
              <button onClick={this.handleRoleSelect} value="buyer" className="roles">Star Buyer</button>
              <button onClick={this.handleRoleSelect} value="rider" className="roles">Star Rider</button>
            </div>)
          }
        </div>
      </div>
    )
  }
}


export default ContactForm