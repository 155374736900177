import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import './footer-component-style.css'


export default () => {
    return (
        <div>
            <div className="Footer">
                <div className="FooterHead">
                    <ul>
                        <li><img src={require('../images/sparkle_logo_white.png')} alt="Sparkle Logo White" /></li>
                        <li><label><b>Download the app</b></label></li>
                        <li><label>Available on App Store and Google Play soon!</label></li>
                    </ul>



                </div>
                <div className="FooterMenu">
                    <ul>
                        <li><AnchorLink to='#home'>HOME</AnchorLink></li>
                        <li><AnchorLink to='#features'>FEATURES</AnchorLink></li>
                        <li><AnchorLink to='#contact'>CONTACT</AnchorLink></li>
                    </ul>
                </div>
                <div className="FooterPolicy">
                    <ul>
                        <li><a href={'https://pdfhost.io/v/YUHlWDCZ6_SPARKLEPRIVACYPOLICYpdf.pdf'}>Privacy Policy</a></li>
                        <li><a href={'https://pdfhost.io/v/0Na043c8G_SparklesCookiePolicypdf.pdf'}>Cookie Policy</a></li>
                        <li><a href={'https://pdfhost.io/v/bKdT4~Kix_TermsofUsepdf.pdf'}>Terms of use</a></li>
                    </ul>
                </div>
                <div className="FooterSocial">
                    <div>
                        <ul>
                            <li>Follow Us!</li>
                        </ul>
                    </div>
                    <div>
                        <ul className="SocialIcons">
                            <li><a href={'https://www.facebook.com/SparkleStar-International-100387048389171/'}><img src={require('../images/facebook_white.png')} /></a></li>
                            <li><a href={'https://twitter.com/sparkles_com_ph'}><img src={require('../images/twitter_white.png')} /></a></li>
                            <li><a href={'https://www.instagram.com/sparkles.com.ph/'}><img src={require('../images/instagram_white.png')} /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="CopyRight">
                <center>Copyright @ 2020 by Sparkle. All rights reserved.</center>
            </div>
        </div>

    )
}
