import React from 'react'
import Img from 'gatsby-image'
import styles from './contact-us.module.css'
import { FaPhoneVolume, FaRegPaperPlane } from 'react-icons/fa'

export default () => (
  <div className={styles.contactUs}>
    <h3 className={styles.contactUsHeadline}>Contact Us</h3>
    <div className={styles.contactUsColumn}>
      <div className={styles.contactUsRow}>
        <FaPhoneVolume color='#5697da' size={50}/>
        <div className={styles.contactUsDetails}>
          <h4 className={styles.contactUsTitle}>09564409300</h4>
          <p className={styles.contactUsSubtitle}>Mon to Fri, 9am to 6pm</p>
        </div>
      </div>
    </div>
    <div className={styles.contactUsColumn}>
      <div className={styles.contactUsRow}>
        <FaRegPaperPlane color='#5697da' size={50}/>
        <div className={styles.contactUsDetails}>
          <h4 className={styles.contactUsTitle}>
            <a href="mailto:hello@sparkles.com.ph">
              hello@sparkles.com.ph
            </a>
          </h4>
          <p className={styles.contactUsSubtitle}>Send us your query anytime!</p>
        </div>
      </div>
    </div>
  </div>
)
